<template>
    <div class="modal-card card">
        <form @submit.prevent="onPromoteJob()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    Promote Job
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body">
                <form-fields :fields="fields" v-model="promotion" />
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>

                <div class="">
                    <div class="table-responsive">
                        <span>Meters</span>
                        <table class="table table-sm table-nowrap mt-2">
                            <thead>
                                <tr class="">
                                    <th class="col-sm-5">Label</th>
                                    <th class="col-sm-5">Metrics Data Channel</th>
                                    <th class="col-sm-5">Metrics Type</th>
                                    <th class="col-sm-5">Metrics Field</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(meter, index) in meters" :key="index">
                                    <td class="col-sm-5 p-2">
                                        <input list="meterLabelOptions" style="padding: 1px 7px;" class="form-control"
                                            v-model="meters[index].label" />
                                        <datalist id="meterLabelOptions">
                                            <option v-for="option in meterLabels" :key="option" :value="option">
                                                {{ option }}
                                            </option>
                                        </datalist>
                                    </td>
                                    <td class="col-sm-5 p-2">
                                        <input list="dataChannelOptions" style="padding: 1px 7px;" class="form-control"
                                            v-model="meters[index].metrics_data_channel"
                                            @change="updateMetricsType(index)" />
                                        <datalist id="dataChannelOptions">
                                            <option v-for="option in dataChannel" :key="option" :value="option">
                                                {{ option }}
                                            </option>
                                        </datalist>
                                    </td>
                                    <td class="col-sm-5 p-2">
                                        <input style="padding: 1px 7px;" class="form-control" type="text"
                                            v-model="meters[index].metrics_type" />
                                    </td>
                                    <td class="col-sm-5 p-2">
                                        <input style="padding: 1px 7px;" class="form-control" type="text"
                                            v-model="meters[index].metrics_field" />
                                    </td>
                                    <td class="col-sm-1">
                                        <i @click="removeMeter(index)" class="fa fa-regular fa-trash-can"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="">
                        <button type="button" class="btn btn-sm btn-primary float-end m-3" @click="onAddMeter"
                            :disabled="promotion.detach_meters">
                            Add Meter
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-footer mt-4">
                <div class="text-end">
                    <submit-button :fields="fields" v-model="promotion" :loading="loading" />
                </div>
            </div>
        </form>
    </div>

</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import axios from "axios";
import { DateTimeFormat } from "../../utils/commonFunction";
import Fields from "../../utils/field-constants/jobPromotionCreateFields"
import { data } from 'jquery';
export default {
    components: { formFields, submitButton },
    data() {
        return {
            error: "",
            promotion: this.modalData.promoteObject,
            fields: Fields,
            loading: false,
            meterLabels: [],
            meters: [],
            saveMeters: [],
            dataChannel: [],
            dataChannelTypeMap: new Map(),
            dataChannelMap: new Map(), // to cache counts request
            latestRunIdMap: new Map(), // to cache latest successful job run id
        }
    },
    name: "Promote Job",
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    computed: {
        sandboxListOptions() {
            return this.$store.state.sandbox.prodV2Sandboxes ? this.$store.state.sandbox.prodV2Sandboxes.map((sandbox) => {
                return { "label": sandbox.name, "value": sandbox.name };
            }) : [];
        },
        jobListOptions() {
            return this.$store.state.sandbox.successfulJobRunList ? this.$store.state.sandbox.successfulJobRunList.map((job) => {
                return { "label": `${job.id}  - ${DateTimeFormat(job.completed_at)}`, "value": job.id };
            }) : [];
        }
    },
    methods: {
        onAddMeter() {
            this.meters.push({
                label: null,
                metrics_type: null,
                metrics_field: null,
                metrics_data_channel: null,
            })
        },
        removeMeter(index) {
            this.meters.splice(index, 1);
        },
        updateMetricsType(index) {
            const selectedDataChannel = this.meters[index].metrics_data_channel;
            const metricsType = this.dataChannelTypeMap.get(selectedDataChannel);
            if (metricsType) {
                this.meters[index].metrics_type = metricsType; // Update the Metrics Type
            }
        },
        async getMeterLabels() {
            await axios.get(`${this.$store.state.lakeviewUrl}/v1/meter-labels`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            })
                .then((response) => {
                    if (response.data && response.data.meter_labels) {
                        this.meterLabels = response.data.meter_labels
                    }
                })
        },
        async onPromoteJob() {
            let self = this;
            this.loading = true;
            // Codemirror adding empty string on inital editor load. Remove the vars key its value is ''.
            if (this.promotion.vars == '') {
                delete this.promotion.vars
            }
            if (!this.promotion.detach_meters && this.meters.length > 0) {
                this.promotion.meters = this.meters
            } else {
                delete this.promotion.meters
            }
            axios.post(`${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/promotions`, this.promotion, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then((response) => {
                self.$store.dispatch('hideModal');
                self.$router.push({
                    name: "Promotion Overview",
                    params: {
                        sandboxId: response.data.dst_sandbox_name,
                        promotionId: response.data.id,
                    }
                });

            }).catch((error) => {
                if (error.response.data.error)
                    this.error = error.response.data.error
                else
                    this.error = error.message

                return
            }).finally(() => {
                this.loading = false;
            })
        },
        async getDataChannels(jobRunId) {
            let sandbox = this.$route.params.sandboxId
            let job = this.$route.params.jobId
            if (!jobRunId) {
                sandbox = this.promotion.dst_sandbox_name
                job = this.promotion.dst_job_name
                jobRunId = await this.getLatestSuccessfulRunId();
            }
            if (sandbox && job && jobRunId)
                this.getDataChannelsForJobRunId(sandbox, job, jobRunId)
        },
        async getLatestSuccessfulRunId() {
            let id = ""
            let cid = this.$store.state.currentClient.cid
            let sandbox = this.promotion.dst_sandbox_name
            let job = this.promotion.dst_job_name
            if (this.latestRunIdMap.has(sandbox + '~' + job)) {
                let data = this.latestRunIdMap.get(sandbox + '~' + job)
                return data
            }
            if (cid && sandbox && job) {
                await axios.get(`${this.$store.state.lakeviewUrl}/v1/cids/${cid}/sandboxes/${sandbox}/jobs/${job}/executions?status=SUCCEEDED&limit=1`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                })
                    .then((response) => {
                        if (response.data.length > 0 && response.data[0].id) {
                            id = response.data[0].id
                            this.latestRunIdMap.set(sandbox + '~' + job, id)
                        }
                    })
            }
            return id
        },
        async getDataChannelsForJobRunId(sandbox, job, jobRunId) {
            if (this.dataChannelMap.has(jobRunId)) { // check cache
                this.dataChannel = this.dataChannelMap.get(jobRunId).dataChannel
                return
            }
            let countsPayload = {};
            countsPayload.filter_dimensions = {
                "cid": this.$route.params.cid,
                "sandbox_name": sandbox,
                "job_name": job,
                "job_run_id": jobRunId
            }
            let metricsPayload = JSON.parse(JSON.stringify(this.$store.state.amdp.countsMetricReqObj));
            delete metricsPayload["amdp-performance-metric"];
            delete metricsPayload["amdp-job-run-params"];
            if (metricsPayload && metricsPayload["amdp-metric"]) {
                metricsPayload["amdp-metric"].header = ["data_channel", "data_channel_type"]
            }
            countsPayload.metrics = metricsPayload
            axios.post(`${this.$store.state.countsUrl}/v2/cids/${this.$store.state.currentClient.cid}/metrics/batch/query`, countsPayload, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then((response) => {
                const dataChannelSet = new Set();
                const dataChannelTypeMap = new Map();
                response.data.data["amdp-metric"].data.forEach(subArray => {
                    const [element1, element2] = subArray; // Destructure the first two elements
                    dataChannelSet.add(element1);        // Add data channel to the Set
                    dataChannelTypeMap.set(element1, element2);    // Map data channel to its type
                });
                this.dataChannel = [...dataChannelSet].sort()
                this.dataChannelTypeMap = dataChannelTypeMap
                this.dataChannelMap.set(jobRunId, { dataChannel: this.dataChannel, dataChannelTypeMap: this.dataChannelTypeMap });
            })
        }
    },
    watch: {
        'promotion.detach_meters': {
            handler(newValue) {
                if (newValue) {
                    this.saveMeters = this.meters
                    this.meters = []
                } else {
                    this.meters = this.saveMeters
                }
            },
            deep: true
        },
        'promotion.src_job_run_id': {
            handler(newValue) {
                this.dataChannel = []
                this.getDataChannels(newValue)
            },
            deep: true
        },
        'promotion.dst_job_name': {
            handler(newValue) {
                this.dataChannel = []
                this.getDataChannels(this.promotion.src_job_run_id)
            },
            deep: true
        }
    },
    async beforeMount() {
        let self = this;
        this.fields = this.fields.map((field) => {
            if (field.name == "dst_sandbox_name") {
                field.options = self.sandboxListOptions;
            } else if (field.name == "src_job_run_id") {
                // Only assigning recent 15 job run ids
                field.options = self.jobListOptions.slice(0, 15);
            } else if (field.name == "dst_job_name") {
                field.options = [{ "label": this.promotion.dst_job_name, "value": this.promotion.dst_job_name }]
            }
            return field;
        });
        this.dataChannel = []
        this.getMeterLabels();
        await this.getDataChannels(this.promotion.src_job_run_id);
    }

}
</script>

<style></style>