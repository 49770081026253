<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddClient()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Add Client
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <h2 class="card-header-title">
                    Client Settings
                </h2>
                <hr />
                <form-fields :fields="clientV2Fields" v-model="client" />
                <br/>
                <h2 class="card-header-title">
                    aUT Settings
                </h2>
                <hr />
                <form-fields :fields="autFields" v-model="autClient" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :fields="combinedFields" v-model="combinedModels" />
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import clientV2Fields from "../../utils/field-constants/clientV2CreateFields"
import autFields from "../../utils/field-constants/autClientCreateFields"
export default {
    name: "AddClient",
    components: { formFields, submitButton },
    data(){
        return {
            error: "",
            client: {},
            autClient: {},
            clientV2Fields: clientV2Fields,
            autFields: autFields
        }
    },
    computed: {
        combinedFields() {
            return [
                ...this.clientV2Fields,
                ...this.autFields
            ]
        },
        combinedModels() {
            return {
                ...this.client,
                ...this.autClient
            } 
        }
    },
    methods: {
        ...mapActions('admin', {
            createClientV2: 'createClientV2',
            createAUTClient: 'createAUTClient'
        }),
        async onAddClient() {
            
            //keep shared fields synced
            this.autClient.cid = this.client.cid
            this.autClient.name = this.client.name

            try {
                await this.createClientV2(this.client)
            } catch (error) {
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message
                
                return
            }
            try {
                await this.createAUTClient(this.autClient)
            } catch (error) {
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message
                
                return
            }

            this.$store.dispatch('hideModal')
            this.$store.dispatch('admin/fetchClientList')
        }
    }
};
</script>

<style>
</style>