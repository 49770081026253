<template>
    <div class="input-container">
        <div class="row">
            <div class="col-md-5">
                <div class="form-group">
                    <label for="personId" class="form-label">Enter Entity Key</label>
                    <input id="personId" v-model="personId" type="text" class="form-control"
                        :class="{ 'is-invalid': !isValidFormat && personId }" placeholder="prsn~domain~uuid"
                        @keyup.enter="handleEnter">
                    <div class="invalid-feedback position-absolute" v-if="!isValidFormat && personId">
                        Invalid format. Expected format: prsn~domain~uuid
                        (e.g., prsn~c900~7702f98b-a8d9-11ef-9451-0242ac110003)
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label for="nodeLevel" class="form-label">Node Level</label>
                    <input id="nodeLevel" v-model.number="nodeLevel" type="number" class="form-control" min="1"
                        placeholder="Enter Level (1-3)">
                </div>
            </div>

            <div class="col-md-2 align-self-center " v-if="personId !== ''">
                <button class="btn btn-primary w-100" @click="updateGraph" :disabled="!isValidFormat">
                    <i class="bi bi-search me-2"></i>
                    Update
                </button>
            </div>
        </div>
    </div>
    <div class="card">
        <div ref="graphContainer" class="graph-container d-flex justify-content-center"></div>
    </div>
</template>

<script>
import * as d3 from "d3"
import { mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            personId: '',
            nodeLevel: 1,
            simulation: null,
            svg: null,
        }
    },

    computed: {
        ...mapGetters('idr', ['getNetworkData']),

        networkData() {
            return this.getNetworkData;
        },
        isValidFormat() {
            // UUID regex pattern
            const uuidPattern = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

            // Complete pattern for personId
            const pattern = new RegExp(`^prsn~[a-z0-9]+~${uuidPattern}$`, 'i');

            return this.personId ? pattern.test(this.personId) : true;
        }
    },

    methods: {
        ...mapActions({
            fetchAndConvertNetworkData: "idr/fetchAndConvertNetworkData",
        }),

        handleEnter() {
            if (this.isValidFormat && this.personId) {
                this.updateGraph();
            }
        },

        async updateGraph() {
            try {
                await this.initNetwork()
            } catch (error) {
                console.error('Search failed:', error)
            }
        },

        async initNetwork() {
            try {
                await this.fetchAndConvertNetworkData({ personId: this.personId, maxLevel: this.nodeLevel })
                const container = this.$refs.graphContainer;
                if (!container) {
                    console.error('Network container not found')
                    return
                }

                const data = this.networkData
                if (!data || !data.nodes || !data.edges) {
                    console.error('Invalid network data:', data)
                    return
                }
                console.log('Initializing network with data:', data)

                let graph = {
                    nodes: [],
                    edges: [],
                }
                data.nodes.forEach(node => {
                    graph.nodes.push({
                        id: node.id,
                        type: node.type,
                        domain: node.domain,
                        key: node.key
                    });
                });
                data.edges.forEach(edge => {
                    graph.edges.push({
                        source: edge.from,
                        target: edge.to
                    });
                });
                this.drawGraph(graph);
            } catch (error) {
                console.log(error);
            }
        },

        drawGraph(data) {
            const width = this.$refs.graphContainer.clientWidth;
            const height = 600;

            // Remove old SVG if it exists
            d3.select(this.$refs.graphContainer).select("svg").remove();

            // Create new SVG with zoom/pan support
            const svg = d3.select(this.$refs.graphContainer)
                .append("svg")
                .attr("width", width)
                .attr("height", height)
                .call(
                    d3.zoom()
                        .scaleExtent([0.5, 5]) // Min zoom 50%, max zoom 500%
                        .on("zoom", (event) => {
                            g.attr("transform", event.transform);
                        })
                );

            // Add a group element for panning/zooming
            const g = svg.append("g");
            this.simulation = d3.forceSimulation(data.nodes)
                .force("link", d3.forceLink(data.edges).id(d => d.id).distance(50))
                .force("charge", d3.forceManyBody().strength(-200))
                .force("center", d3.forceCenter(width / 2, height / 2));

            // Create edges (links)
            const link = g.selectAll(".link")
                .data(data.edges)
                .enter()
                .append("line")
                .attr("stroke", "#999")
                .attr("stroke-width", 1.5)
                .attr("stroke-opacity", 0.6);

            // Create node groups
            const node = g.selectAll(".node")
                .data(data.nodes)
                .enter()
                .append("g")
                .attr("class", "node")
                .call(
                    d3.drag()
                        .on("start", (event, d) => {
                            if (!event.active) this.simulation.alphaTarget(0.3).restart();
                            d.fx = d.x;
                            d.fy = d.y;
                        })
                        .on("drag", (event, d) => {
                            d.fx = event.x;
                            d.fy = event.y;
                        })
                        .on("end", (event, d) => {
                            if (!event.active) this.simulation.alphaTarget(0);
                            d.fx = null;
                            d.fy = null;
                        })
                );

            // Add circles for nodes
            node.append("circle")
                .attr("stroke", "white") // Border color
                .attr("stroke-width", 1.5) // Border thickness
                .attr("r", d => { // Circle radius
                    if (d.type === "prsn") return 15;
                    return 12;
                })
                .attr("fill", d => { // Circle colour
                    if (d.type === "prsn") return "blue";
                    if (d.type === "cookie") return "orange";
                    if (d.type === "statid") return "green";
                    return "gray";
                });

            // Add images inside the circles
            node.append("image")
                .attr("xlink:href", d => {
                    if (d.type === "prsn") return "/icons/prsnNode.png";
                    if (d.type === "cookie") return "/icons/cookieNode.png";
                    if (d.type === "statid") return "/icons/statidNode.png";
                    return "/icons/defaultNode.png";
                })
                // Image size (adjust based on circle size)
                .attr("width", d => {
                    if (d.type === "prsn") return 15;
                    return 12;
                })  
                .attr("height", d => {
                    if (d.type === "prsn") return 15;
                    return 12;
                })
                // Center image inside circle
                .attr("x", d => {
                    if (d.type === "prsn") return -7.5;
                    return -6;
                })
                .attr("y", d => {
                    if (d.type === "prsn") return -7.5;
                    return -6;
                });

            // Tooltip for nodes
            node.on("mouseover", (event, d) => {
                const tooltip = document.createElement("div");
                tooltip.innerHTML = `<strong>Type: ${d.type}</strong><br>Domain: ${d.domain}<br>Key: ${d.key}`;
                tooltip.className = "graph-tooltip";
                tooltip.style.left = event.pageX + 5 + "px";
                tooltip.style.top = event.pageY + 5 + "px";
                document.body.appendChild(tooltip);
            });
            node.on("mouseout", () => {
                document.querySelectorAll(".graph-tooltip").forEach(tooltip => tooltip.remove());
            });

            // Update positions on simulation tick
            this.simulation.on("tick", () => {
                link
                    .attr("x1", d => d.source.x)
                    .attr("y1", d => d.source.y)
                    .attr("x2", d => d.target.x)
                    .attr("y2", d => d.target.y);
                node.attr("transform", d => `translate(${d.x},${d.y})`);
            });
        }
    },

    beforeUnmount() {
        if (this.simulation) {
            this.simulation.stop();
        }
    }
}
</script>

<style>
.graph-tooltip {
    position: absolute;
    background-color: #6E84A3;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    pointer-events: none;
}
.graph-container {
    flex: 1; /* Fills the entire card */
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    background-color: #fff;
}
</style>
