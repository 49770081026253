function getAutGeneralFields() { 
    return[
        {
           
            name: "version",
            label: "Version",
            type: "text",
            toolTip: "Version number of the configuration. Activation copies the version from STAGING to PRODUCTION.",
            editable: false,
            visible: true
        },
        {
            name: "name",
            label: "Name",
            type: "text",
            editable: true,
            visible: true            
        },       
        {
            name: "lastModifiedBy",
            label: "Updated By",
            type: "text",
            editable: false,
            visible: true,            
        },
        {
            name: "lastModifiedAt",
            label: "Updated At",
            type: "text",
            editable: false,
            visible: true,  
            format:"datetime"         
        },
        {
            name: "cookieName",
            label: "Cookie Name",
            toolTip: "Name of the cookie used by the client for identifying their unique users.",
            type: "text",
            editable: true,
            visible: true
        },
        {
            name: "cookieSameSite",
            label: "SameSite Cookie Status",
            type: "radio",
            tooltip:"Set the Samesite attribute when configuring cookie",
            editable: true,
            visible: true,
            options:[
                { label: "None", value: "None" },
                { label: "Strict", value: "Strict" },
                { label: "Lax", value: "Lax" },
            ] 
        }, 
        {
            name: "setPartitionedCookie",
            label: "Set Partitioned Cookie",
            type: "checkbox",      
            tooltip:"Set partitioned and unpartitioned cookies in Chrome when enabled",  
            editable: true,
            visible: true
        } ,     
        {
            name: "pubDenyDomains",
            label: "Deny Publisher Domains",
            type: "array",
            editable: true,
            visible: true,
            toolTip: "List of publisher domains for which the container will not be loaded."
        },
        {
            name: "pubDenyIds",
            label: "Deny Publisher Ids",
            type: "array",
            editable: true,
            visible: true,
            toolTip: "List of publisher ids for which the container will not be loaded."
        },  
        {
            name: "domainLimit",
            label: "Domain Limit",
            type: "number",
            editable: true,
            visible: true,
            readOnlyIfRoleOver: 1,
            toolTip: "Number of hostnames per client."
        },
        {
            name: "dailyCountEmails",
            label: "Daily Count Emails",
            type: "multiselect",
            editable: true,
            visible: true,
            toolTip: "List of email ids to which daily counts emails will be sent.",
            validation: [
                { type: "email" }
            ]
        },        
        {
            name: "gdpr.consentStringParamName",
            label: "Consent String Parameter",
            toolTip: "Parameter name of consent string",
            type: "text",
            editable: false,
            visible: true
           
        },       
        {
            name: "usPrivacy.privacyStringParamName",
            label: "CPRA Privacy String Parameter",
            toolTip: 'Parameter name of privacy string',
            type: "text",
            editable: true,
            visible: true,
            readOnlyIfRoleOver: 1           
        },
        {
            name: "gpp.tcf.status",
            label: "TCF",
            tooltip: "TCF is applicable if gpp_sid=2: 1.BLOCKED:The traffic is blocked 2.DISABLED:TCF has been handled 3.ENABLED:TCF is handled ",
            type: "radio",           
            editable: true,
            visible: true,
            options:[
                { label: "DISABLED", value: "DISABLED" },
                { label: "BLOCKED", value: "BLOCKED" },
                { label: "ENABLED", value: "ENABLED" }
            ]
        },
        {
            name: "gpp.tcf.gvlId",
            label: "TCF Gvl Id",
            tooltip: "TCF Global vendor List Identifier",                     
            editable: true,
            visible: true,
            defaultValue: null,
            validation: [
                { 
                    type: "regex", pattern: "^(null|[0-9]+)?$",
                    errMsg:"TCF Gvl Id should be a numeric value"
                }
            ]      
        },
        {
            name: "gpp.ccpa.status",
            label: "CPRA",
            tooltip:"CPRA is applicable if gpp_sid=6: 1.BLOCKED:The traffic is blocked 2.DISABLED:CPRA has been handled 3.ENABLED:CPRA is handled",
            type: "radio",           
            editable: true,
            visible: true,
            options:[
                { label: "DISABLED", value: "DISABLED" },
                { label: "BLOCKED", value: "BLOCKED" },
                { label: "ENABLED", value: "ENABLED" }
            ]
        },
        {
            name: "gpp.usMultiStatePrivacy",
            label: "US Multi State Privacy",
            toolTip: "When US Multi states and US National privacy is applicable, 1.BLOCKED: The traffic is blocked 2.DISABLED: Privacy has been handled 3.ENABLED: Privacy is handled",
            type: "custom",    
            toolTipHtml: "true",       
            editable: true,
            customComponent: true,
            component: "UsMultiStatePrivacy"

        },
        {
            name: "customHeaders",
            label: "Custom Headers",
            toolTip: 'List of custom headers to be served through the client\'s responses.',
            type: "object",
            inputType: "json",
            editable: true,
            visible: true
        },        
        {
           
            name: "akamaiProperty",
            label: "Akamai Property",
            type: "text",
            editable: true,
            visible: true
        },
        {
            name: "optoutCookie",
            label: "Opt-Out Cookie",
            tooltip:"Cookie to set to opt out an user",
            type: "custom",
            editable: false,
            customComponent: true,
            component: "JsonField"
        },        
        {
            name: "logCookies",
            label: "Log Request Cookies",
            tooltip:"Choose to log all cookies or specify which cookies to log",
            type: "radio",
            editable: true,
            visible: true,
            options:[
                { label: "ALL", value: "ALL" },
                { label: "SPECIFIC", value: "SPECIFIC" }
            ] 
        },
        {
            name: "cookieList",
            label: "Specific Cookies to log",
            toolTip: 'List of case sensitive comma separated cookies to log. This applies to all classes',
            type: "multiselect",
            editable: true,
            visible: true,
            suppressIfField: 'logCookies', 
            suppressIfValueContains: ['ALL'] 
        },
        {
            name: "log_cookies.system",
            label: "System Cookies To Log",
            type: "multiselect",
            editable: false,
            visible: true,
        }
        
        
    ]

}

export default getAutGeneralFields;