<template>
    <div class="modal-card card">
        <form @submit.prevent="handleSubmit">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    Add Meters
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body">
                <div class="">
                    <div class="table-responsive">
                        <span>Meters</span>
                        <table class="table table-sm table-nowrap mt-2">
                            <thead>
                                <tr class="">
                                    <th class="col-sm-5">Label</th>
                                    <th class="col-sm-5">Metrics Data Channel</th>
                                    <th class="col-sm-5">Metrics Type</th>
                                    <th class="col-sm-5">Metrics Field</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(meter, index) in meters" :key="index">
                                    <td class="col-sm-5 p-2">
                                        <input list="meterLabelOptions" style="padding: 1px 7px;" class="form-control"
                                            v-model="meters[index].label" />
                                        <datalist id="meterLabelOptions">
                                            <option v-for="option in meterLabels" :key="option" :value="option">
                                                {{ option }}
                                            </option>
                                        </datalist>
                                    </td>
                                    <td class="col-sm-5 p-2">
                                        <input list="dataChannelOptions" style="padding: 1px 7px;" class="form-control"
                                            v-model="meters[index].metrics_data_channel"
                                            @change="updateMetricsType(index)" />
                                        <datalist id="dataChannelOptions">
                                            <option v-for="option in dataChannel" :key="option" :value="option">
                                                {{ option }}
                                            </option>
                                        </datalist>
                                    </td>
                                    <td class="col-sm-5 p-2">
                                        <input style="padding: 1px 7px;" class="form-control" type="text"
                                            v-model="meters[index].metrics_type" />
                                    </td>
                                    <td class="col-sm-5 p-2">
                                        <input style="padding: 1px 7px;" class="form-control" type="text"
                                            v-model="meters[index].metrics_field" />
                                    </td>
                                    <td class="col-sm-1">
                                        <i @click="removeMeter(index)" class="fa fa-regular fa-trash-can"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="">
                        <button type="button" class="btn btn-sm btn-primary float-end" @click="onAddMeter">
                            Add Meter
                        </button>
                    </div>
                </div>
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
            </div>
            <div class="card-footer mt-4">
                <div class="text-end">
                    <button class="btn btn-primary" :disabled="buttonState">
                        <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else>
                            <i v-if="buttonState" class="fa fa-circle-exclamation" title="Please add atleast one meter"
                                data-bs-toggle="tooltip" data-bs-placement="left"></i>
                            Submit
                        </div>
                    </button>
                </div>
            </div>
        </form>
    </div>

</template>

<script>
import axios from "axios";
export default {
    name: "Add Meters",
    data() {
        return {
            error: "",
            jobDetails: this.modalData.jobDetails,
            loading: false,
            meters: [],
            dataChannel: [],
            dataChannelTypeMap: new Map(),
            meterLabels: [],
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    emits: ['refreshJobData'],
    computed: {
        buttonState() {
            if (this.meters.length === 0) return true; // Disable if no meters added
            // Check if any meter has missing fields
            return this.meters.some(meter =>
                !meter.label ||
                !meter.metrics_type ||
                !meter.metrics_field ||
                !meter.metrics_data_channel
            );
        }
    },
    methods: {
        onAddMeter() {
            this.meters.push({
                label: null,
                metrics_type: null,
                metrics_field: null,
                metrics_data_channel: null,
            })
        },
        removeMeter(index) {
            this.meters.splice(index, 1);
        },
        updateMetricsType(index) {
            const selectedDataChannel = this.meters[index].metrics_data_channel;
            const metricsType = this.dataChannelTypeMap.get(selectedDataChannel);
            if (metricsType) {
                this.meters[index].metrics_type = metricsType; // Update the Metrics Type
            }
        },
        async getMeterLabels() {
            await axios.get(`${this.$store.state.lakeviewUrl}/v1/meter-labels`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            })
                .then((response) => {
                    if (response.data && response.data.meter_labels) {
                        this.meterLabels = response.data.meter_labels
                    }
                })
        },
        async getLatestSuccessfulRunId() {
            let id = ""
            let cid = this.jobDetails.cid
            let sandbox = this.jobDetails.sandboxName
            let job = this.jobDetails.jobName
            if (cid && sandbox && job) {
                await axios.get(`${this.$store.state.lakeviewUrl}/v1/cids/${cid}/sandboxes/${sandbox}/jobs/${job}/executions?status=SUCCEEDED&limit=1`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                })
                    .then((response) => {
                        if (response.data.length > 0 && response.data[0].id) {
                            id = response.data[0].id
                            this.latestRunId = id
                        }
                    })
            }
            return id
        },
        async getDataChannelsForJobRunId() {
            let jobRunId = await this.getLatestSuccessfulRunId()
            let countsPayload = {};
            countsPayload.filter_dimensions = {
                "cid": this.$route.params.cid,
                "sandbox_name": this.jobDetails.sandboxName,
                "job_name": this.jobDetails.jobName,
                "job_run_id": jobRunId
            }
            let metricsPayload = JSON.parse(JSON.stringify(this.$store.state.amdp.countsMetricReqObj));
            delete metricsPayload["amdp-performance-metric"];
            delete metricsPayload["amdp-job-run-params"];
            if (metricsPayload && metricsPayload["amdp-metric"]) {
                metricsPayload["amdp-metric"].header = ["data_channel", "data_channel_type"]
            }
            countsPayload.metrics = metricsPayload
            axios.post(`${this.$store.state.countsUrl}/v2/cids/${this.$store.state.currentClient.cid}/metrics/batch/query`, countsPayload, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then((response) => {
                const dataChannelSet = new Set();
                const dataChannelTypeMap = new Map();
                response.data.data["amdp-metric"].data.forEach(subArray => {
                    const [element1, element2] = subArray; // Destructure the first two elements
                    dataChannelSet.add(element1);        // Add data channel to the Set
                    dataChannelTypeMap.set(element1, element2);    // Map data channel to its type
                });
                this.dataChannel = [...dataChannelSet].sort()
                this.dataChannelTypeMap = dataChannelTypeMap
            })
        },
        handleSubmit() {
            this.loading = true
            let cid = this.jobDetails.cid
            let sandbox = this.jobDetails.sandboxName
            let job = this.jobDetails.jobName
            axios.put(`${this.$store.state.lakeviewUrl}/v1/cids/${cid}/sandboxes/${sandbox}/jobs/${job}/meters`, this.meters, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then((response) => {
                this.$store.dispatch('hideModal');
            }).catch((error) => {
                if (error.response.data.error)
                    this.error = error.response.data.error
                else
                    this.error = error.message
                return
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    beforeMount() {
        // get existing job meters if any
        if (this.jobDetails.meters) {
            this.jobDetails.meters.forEach(meter => {
                this.meters.push({
                    label: meter.label,
                    metrics_type: meter.metrics_type,
                    metrics_field: meter.metrics_field,
                    metrics_data_channel: meter.metrics_data_channel,
                })
            })
        }

        // get data channels to be listed in dropdown
        this.getMeterLabels();
        this.getDataChannelsForJobRunId()
    }
}
</script>

<style></style>