<template>
    <div class="execution-details">
        <div v-if="loading" class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else class="row left-nav-collapsible">
            <div v-if="detailPageOpen" id="detail-page-open">
                <button class="btn details-open-btn" type="button" @click="onDetailPageOpenClose(false)">
                    <span> <i class="fa fa-caret-right"></i></span>
                </button>
            </div>
            <TransitionGroup :name="jobName ? 'slide' : ''" key="detail">
                <div v-if="showCloseBtn && !detailPageOpen" id="detail-page-close" key="close">
                    <button class="btn text-start details-open-btn" type="button" @click="onDetailPageOpenClose(true)">
                        <span> <i class="fa fa-caret-left"></i></span>
                    </button>
                </div>
                <div v-if="!detailPageOpen" id="details-col" @mouseover="onDetailPageHover(true)" key="details-col">
                    <div class="card">
                        <div class="card-body">
                            <div class="list-group list-group-flush my-n3">
                                <div class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h5 class="mb-0">Status</h5>
                                        </div>
                                        <div class="col-auto">
                                            <strong :class="[statusClass, 'text-uppercase']">{{ status }}</strong>
                                            <div v-if="status && status.toLowerCase() != 'failed' && status.toLowerCase() != 'succeeded' && status.toLowerCase() != 'cancelled' && count < 10"
                                                style="margin-left:10px"
                                                class="spinner-border spinner-border-sm text-primary">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h5 class="mb-0">Job Name</h5>
                                        </div>
                                        <div class="col-auto">
                                            <small class="text-muted">{{ jobName }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="cluster" class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h5 class="mb-0">Cluster</h5>
                                        </div>
                                        <div class="col-auto">
                                            <small class="text-muted">{{ cluster }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="gitCommit" class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h5 class="mb-0">Commit (trunc)</h5>
                                        </div>
                                        <div class="col-auto">
                                            <small class="text-muted">{{ gitCommit }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="submittedAt" class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h5 class="mb-0">Submitted</h5>
                                        </div>
                                        <div class="col-auto">
                                            <small class="text-muted">{{ submittedAt.format('MMM D, YYYY h:mm:ss A')
                                                }}</small>
                                            <span class="badge badge-secondary">{{ submittedRelativeTime }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="startedAt" class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h5 class="mb-0">Started</h5>
                                        </div>
                                        <div class="col-auto">
                                            <small class="text-muted">
                                                {{ dayjs(startedAt).format('MMM D, YYYY h:mm:ssA') }}
                                            </small>
                                            <span class="badge badge-secondary">{{ startedAtRelativeTime }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="startedBy" class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h5 class="mb-0">Started By</h5>
                                        </div>
                                        <div class="col-auto">
                                            <small class="text-muted">{{ startedBy }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="completedAt" class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h5 class="mb-0">Completed</h5>
                                        </div>
                                        <div class="col-auto">
                                            <small class="text-muted">{{
                                                dayjs(completedAt).format('MMM D, YYYY h:mm:ss A') }}</small><span
                                                class="badge badge-secondary">{{
                                                    convertToRelativeTime(dayjs(completedAt)) }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="queueTime" class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h5 class="mb-0">Queue Time</h5>
                                        </div>
                                        <div class="col-auto">
                                            <small class="text-muted">{{ convertToTime(queueTime) }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="runtime" class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h5 class="mb-0">Runtime</h5>
                                        </div>
                                        <div class="col-auto">
                                            <small class="text-muted">{{ convertToTime(runtime) }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tag" class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h5 class="mb-0">Tag</h5>
                                        </div>
                                        <div class="col-auto">
                                            <small class="text-muted">{{ tag }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="failureReason" class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h5 class="mb-0">Failure Reason</h5>
                                        </div>
                                        <div class="col-auto">
                                            <small class="text-muted">{{ failureReason }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col" id="main-col" @mouseover="onDetailPageHover(false)" key="main-col">
                    <div class="row justify-content-end" v-if="enableRefreshBtn">
                        <div class="col-sm-3 d-flex position-absolute justify-content-end">
                            <button @click.prevent="onRefreshClick()" :disabled="refreshBtnLoading"
                                class="btn btn-sm btn-primary rounded-2 custom-refresh-btn">
                                <div v-if="refreshBtnLoading" class="spinner-border spinner-border-sm" role="status" />
                                Refresh
                            </button>
                        </div>
                    </div>
                    <tabs :tabs="tabItems" :btnTabs="true">
                        <template v-slot:logs>
                            <div class="row">
                                <div class="col-md-12" v-if="logOptions.length">
                                    <div class="card mb-n2 no-border">
                                        <div class="card-body">
                                            <input-field label="Select Log Type" type="select" :options="logOptions"
                                                v-model="selectedLogType" class="mb-0" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" v-if="selectedLogType == 'logs'">
                                    <div class="card no-border">

                                        <!-- <div class="card-header">
                                            <h4 class="card-header-title">
                                                Logs
                                            </h4>
                                            <div v-if="status && status.toLowerCase() != 'failed' && status.toLowerCase() != 'succeeded' && status.toLowerCase() != 'cancelled'"
                                                class="spinner-border spinner-border-sm float-end">&nbsp;</div>

                                        </div> -->
                                        <div class="card-body">
                                            <pre class="mb-0" v-if="logs" id="logs"><code>{{ logs }}</code></pre>
                                            <pre v-else><code>please wait...</code></pre>
                                            <pre class="mt-2 downloadLink "
                                                v-if="isDownloading"><code>Downloading...</code></pre>
                                            <div class="justify-content-center d-flex" v-if="enableShowMoreBtn">
                                                <button class="btn btn-sm btn-secondary mt-3 small-btn" @click="onShowMoreClick" :disabled="showMoreBtnLoading">
                                                    <div v-if="showMoreBtnLoading" class="spinner-border spinner-border-sm" style="width: 1em; height: 1em;" role="status" />
                                                    Show more
                                                </button>
                                            </div>
                                            <div v-if="outputLogLink" class="p-2 downloadLink">
                                                <a :href="outputLogLink"
                                                    @click.prevent="downloadFile(outputLogLink, executionId + '_outputLog')"><span
                                                        class="fa fa-download"></span>&nbsp;Output Log</a>&nbsp; {{
                                                            outputLog }} MB
                                            </div>
                                            <div v-if="errorLogLink" class="p-2 mb-2 downloadLink">
                                                <a :href="errorLogLink"
                                                    @click.prevent="downloadFile(errorLogLink, executionId + '_errorLog')"><span
                                                        class="fa fa-download"></span>&nbsp;Error
                                                    Log&nbsp;&nbsp;&nbsp;&nbsp;</a> &nbsp;{{ errorLog }} MB
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card"
                                        v-if="status && status.toLowerCase() == 'succeeded' && outputPaths.length">
                                        <div class="card-header">
                                            <h4 class="card-header-title">
                                                Output Paths
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <ul>
                                                <li v-for="path in outputPaths" :key="path.index">{{ path }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" v-if="selectedLogType !== 'logs'">
                                    <div class="card no-border">
                                        <div class="card-body pt-0" id="logs">
                                            <JobExecutorLogs :sandboxName="sandboxId" :jobName="jobName"
                                                :executionId="executionId" :executorsId="selectedLogType"
                                                :jobStatus="status"></JobExecutorLogs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:logStreams>
                            <div class="card">
                                <div class="row justify-content-end">
                                    <div v-if="logStreams"
                                        class="col-md-9 mt-4 d-flex position-absolute justify-content-end">
                                        <div class="custom-control form-check  me-5 form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch"
                                                id="AbbLogStreamSwitch" v-model="showLogDataInUnits">
                                            <label class="form-check-label" for="AbbLogStreamSwitch">Show Abbreviated
                                                Data</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body p-2" v-if="logStreams && logStreams.length">
                                    <datatable tableId="log-streams" :columns="logStreamColumns" :data="logStreams"
                                        :formatColumn="logStreamFormatColumn" :key="showLogDataInUnits" :compact="true"
                                        :showLoading="false" @viewModal="openModal"
                                        :viewModalColumn="viewModalColumn" />
                                </div>
                                <div v-else class="card-body">
                                    <p>
                                    <pre>No log streams found!</pre>
                                    </p>
                                </div>
                            </div>
                        </template>
                        <template v-slot:[currentLogStreamId]>
                            <div class="card no-border">
                                <div class="card-body">
                                    <pre class="mb-0" id="logs"><code>{{ logStreamsData.Logs }}</code></pre>
                                </div>

                            </div>

                        </template>
                        <template v-slot:metrics>
                            <div class="card">
                                <div class="card-body">
                                    <div v-if="metricLoading" class="text-center mt-5">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    <div v-else-if="countsV2Metrics && Object.keys(countsV2Metrics).length">
                                        <MetricsView :metricData="countsV2Metrics"
                                            :jobKind="isPromotion ? null : job.kind" :startedAt="startedAt"
                                            :completedAt="status == 'running' ? currentUtcTime : completedAt"
                                            :timeRange="timeRange" :aggInterval="aggInterval"
                                            @callGetCountV2Metrics="getCountV2Metrics"
                                            @onTimeRangeChange="timeRangeChange" @onIntervalChange="aggIntervalChange"
                                            :customStartTime="customStartTime" :customEndTime="customEndTime"
                                            :executionId="executionId" @apply-custom-range="handleCustomRange" />
                                    </div>
                                    <div v-else id="lake-view-metrics" class="row">
                                        <div class="col">
                                            <div class="row justify-content-end">
                                                <div v-if="metrics"
                                                    class="col-md-9 mt-4 d-flex position-absolute justify-content-end">
                                                    <div v-if="showMetricsFilterBtn"
                                                        class="custom-control form-check  me-5 form-switch">
                                                        <input class="form-check-input" type="checkbox" role="switch"
                                                            id="customSwitch1" v-model="showAllMetrics">
                                                        <label class="form-check-label" for="customSwitch1">All
                                                            Metrics</label>
                                                    </div>
                                                    <div class="custom-control form-check  me-5 form-switch">
                                                        <input class="form-check-input" type="checkbox" role="switch"
                                                            id="customSwitch2" v-model="showDataInUnits">
                                                        <label class="form-check-label" for="customSwitch2">Show
                                                            Abbreviated Data</label>
                                                    </div>
                                                </div>
                                                <div v-else class="card-body">
                                                    <p>
                                                    <pre>{{ metricsErrorMsg }}</pre>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="card-body p-2" v-if="metrics && metrics.length">
                                                <datatable :tableId="executionId" :columns="metricColumns"
                                                    :data="filteredMetrics" :compact="true" :showLoading="false" />
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:config v-if="Object.keys(yamlContent).length !== 0">
                            <div class="card">
                                <div class="card-body" id="execution-config-div">
                                    <job-editor v-on:tabChange="showTab" :editMode="false" v-model="yamlContent"
                                        :activeTab="activeTab"></job-editor>
                                </div>
                            </div>
                        </template>
                        <template v-slot:progress v-if="this.status == 'running'">
                            <div class="card">
                                <div class="card-body" id="progress-config-div">
                                    <JobExecutionProgress :sandboxName="sandboxId" :jobName="jobName"
                                        :executionId="executionId"></JobExecutionProgress>
                                </div>
                            </div>
                        </template>

                    </tabs>
                </div>
            </TransitionGroup>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import Datatable from "../../../components/Datatable.vue";
import { secondsToHourMinSec, formatBytes, relativeTime } from '../../../utils/commonFunction';
import Tabs from "../../../components/Tabs.vue";
import JobEditor from '../../../components/sandbox/JobEditor.vue';
import JobExecutionProgress from '../../../components/sandbox/JobExecutionProgress.vue';
import JobExecutorLogs from '../../../components/sandbox/JobExecutorLogs.vue';
import MetricsView from '../../../components/sandbox/Metrics.vue';
import { mapState, mapActions } from "vuex";
import InputField from "../../../components/InputField.vue";
dayjs.extend(duration)
dayjs.extend(utc)

export default {
    components: { Datatable, Tabs, JobEditor, JobExecutionProgress, JobExecutorLogs, InputField, MetricsView },
    data() {
        return {
            tabItems: [
                {
                    name: "Progress",
                    id: "id1",
                    slot: "progress",
                    hide: true
                },
                {
                    name: "Metrics",
                    id: "id3",
                    slot: "metrics"
                },
                {
                    name: "Logs",
                    id: "id2",
                    slot: "logs"
                },
                {
                    name: "Log Streams",
                    id: "id5",
                    slot: "logStreams"

                },
                {
                    name: "Config",
                    id: "id4",
                    slot: "config",
                    hide: true
                }


            ],
            jobObj: this.job,
            name: null,
            status: null,
            statusClass: 'text-primary',
            cluster: null,
            gitCommit: null,
            submittedAt: null,
            submittedRelativeTime: null,
            startedAt: null,
            startedAtRelativeTime: null,
            startedBy: null,
            completedAt: null,
            completedAtRelativeTime: null,
            queueTime: null,
            runtime: null,
            failureReason: null,
            duration: 0,
            tag: null,
            executionId: this.$route.params.executionId,
            jobName: this.$route.params.jobId,
            sandboxId: this.$route.params.sandboxId,
            logs: "",
            logsError: false,
            breakCheck: false,
            outputPaths: [],
            metrics: null,
            defaultMetrics: null,
            filteredMetrics: null,
            metricColumns: [],
            showAllMetrics: false,
            showDataInUnits: true,
            showMetricsFilterBtn: true,
            yamlContent: {},
            activeTab: "",
            outputLogLink: null,
            errorLogLink: null,
            outputLog: 0,
            errorLog: 0,
            isDownloading: null,
            metricsErrorMsg: null,
            loading: true,
            executorsArray: [],
            selectedLogType: "logs",
            detailPageOpen: false,
            showCloseBtn: false,
            logStreams: [],
            logStreamColumns: [],
            logStreamFormatColumn: [
                {
                    index: 4,
                    type: "datetime",
                },
            ],
            showLogDataInUnits: true,
            currentLogStream: null,
            currentLogStreamId: null,
            logStreamsData: [],
            viewModalColumn: 1,
            countsV2Metrics: {},
            metricLoading: true,
            timeRange: (this.job && this.job.kind == 'MAPPER-ΜBATCH') ? "overall" : null,
            aggInterval: null,
            customStartTime: null,
            customEndTime: null,
            currentUtcTime: dayjs.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            count: 1,
            executionStatus: false,
            runData1: {},
            enableRefreshBtn: false,
            enableShowMoreBtn: false,
            refreshBtnLoading: false,
            showMoreBtnLoading: false,
        }
    },
    props: ["cancelJobTrigger", "isSandbox", "saveJobConfigTrigger", "job", "isPromotion"],
    computed: {
        baseEndpoint() {
            let endpoint = `${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/sandboxes/`;
            return endpoint + this.$store.state.sandbox.currentSandbox
        },

        ...mapState({
            jobKinds: (state) => state.amdp.jobKinds
        }),
        logOptions() {
            let options = [];
            if (this.executorsArray.length) {
                options.push({ "label": "Driver Logs", "value": "logs" });
                for (const log of this.executorsArray) {
                    options.push({ "label": log.id, "value": log.id });
                }
            }
            return options;
        },
    },
    watch: {
        cancelJobTrigger() {
            this.cancelJob();
        },
        showAllMetrics(newVal) {
            if (newVal)
                this.filteredMetrics = this.metrics;
            else
                this.filterInputAndOutMetrics();
        },
        showDataInUnits(newVal) {
            this.convertMetricDataToUnits();
        },
        saveJobConfigTrigger() {
            this.saveJob()
        },
        job(newVal) {
            this.jobObj = newVal
        }
    },
    methods: {
        ...mapActions({
            showModal: "showModal",
            cancelSandboxJobExecution: "sandbox/cancelSandboxJobExecution",
            updateSandboxJob: "sandbox/updateSandboxJob",
        }),
        dayjs,
        async cancelJob() {
            try {
                this.$emit('setRunClick', true);
                const response = await this.cancelSandboxJobExecution({ jobName: this.jobName, executionId: this.executionId });
                this.response = 'Job cancelled successfully.'
            } catch (error) {
                if (error.response && error.response.data && error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;

                const options = {
                    message: self.error || "Error while cancelling Job",
                    type: "error",
                };
                this.$store.dispatch("addToast", options);
            } finally {
                this.loading = false;
                this.$emit('setRunClick', false);
                this.$emit('showCancelJob', false);
            }

        },
        async handleJobChecking() {
            if (this.breakCheck)
                return;

            const logsPromise = this.getExecutionLogs()
            const statusPromise = await this.getExecutionStatus()

            if (this.count < 10 && (this.logsError || (this.status && this.status != 'failed' && this.status != 'succeeded' && this.status != 'cancelled'))) {
                this.count++;
                await new Promise(resolve => setTimeout(resolve, 2000)); // sleep for 2s
                await this.handleJobChecking();
            } else {
                this.$emit('showCancelJob', false);
                if (this.isPromotion) {
                    this.$emit('getPromotionRunList', true);
                }
            }
        },
        getExecutionStatus() {
            let self = this;
            return axios.get(this.baseEndpoint + `/jobs/${this.jobName}/executions/${this.executionId}?include_metrics=all`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then((response) => {
                self.loading = false;
                if (response.data) {
                    this.executionStatus = true
                    this.runData1 = response.data;
                    const runData = response.data;
                    this.status = runData.status.toLowerCase();
                    if (this.status == 'succeeded') {
                        this.statusClass = 'text-success'
                    } else if (this.status == 'failed') {
                        this.statusClass = 'text-danger'
                        this.failureReason = runData.failure_reason
                    } else if (this.status == 'cancelled') {
                        this.statusClass = 'text-danger';
                        this.logs = "No Log files";
                    } else {
                        this.$emit('showCancelJob', true);
                    }


                    this.name = runData.name;
                    this.cluster = runData.cluster;

                    this.gitCommit = runData.git_commit ? runData.git_commit.slice(0, 8) : null;
                    this.tag = runData.tag;

                    this.startedBy = runData.created_by
                    if (runData.completed_at) {
                        this.completedAt = runData.completed_at;
                    }

                    if (runData.submitted_at) {
                        this.submittedAt = dayjs(runData.submitted_at);
                        this.submittedRelativeTime = this.convertToRelativeTime(this.submittedAt);
                    }

                    if (runData.started_at) {
                        this.startedAt = runData.started_at;
                        this.startedAtRelativeTime = this.convertToRelativeTime(dayjs(this.startedAt));
                    }
                    if (runData.queue_time) {
                        this.queueTime = runData.queue_time
                    }
                    if (runData.run_time) {
                        this.runtime = runData.run_time
                    }

                    if (this.metrics?.length && this.status == 'succeeded') {
                        this.$store.dispatch("sandbox/fetchSandboxJobRuns", { jobName: this.job.name });
                        this.$emit('hidePromoteBtn', false);
                    }

                    // Turnoff progress tag based on job status
                    this.tabItems = this.tabItems.map(function (el) {
                        if ((el.slot == "progress") && self.status == "running" && el.hide) {
                            self.$store.dispatch('setActiveTab', "Progress");
                            el.hide = false;
                        } else if ((el.slot == "progress") && self.status != "running" && !el.hide) {
                            el.hide = true;
                            self.$store.dispatch('setActiveTab', "Metrics")
                        }
                        return el
                    });



                }
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;

                if (!this.status) {
                    let toastoptions = {
                        message: "Something went wrong while fetching execution details. Please contact support.",
                        type: "error"
                    }
                    this.$store.dispatch('addToast', toastoptions);
                }

            });
        },
        checkAndGetCountsMetrics() {
            if (this.executionStatus) {
                const targetDate = new Date('2024-09-12');
                this.metrics = this.runData1.metrics;
                if (this.runData1.status === "RUNNING") {
                    this.getCountV2Metrics();
                } else {
                    if (new Date(this.runData1.completed_at) > targetDate) {
                        this.getCountV2Metrics();
                    } else {
                        if (this.runData1.metrics && this.runData1.metrics) {
                            this.metrics = this.runData1.metrics;
                            this.convertMetricDataToUnits();
                        } else {
                            this.metricLoading = false;
                            this.metricsErrorMsg = "No metrics found!";
                        }
                    }
                }
                this.executionStatus = false
            }
        },
        getExecutors() {
            return axios.get(this.baseEndpoint + `/jobs/${this.jobName}/executions/${this.executionId}/logs/executors`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                if (response.data)
                    this.executorsArray = response.data.executors || [];
                else
                    this.executorsArray = [];
            }).catch((error) => {
                this.executorsArray = [];
            })

        },
        getLogStreams() {
            return axios.get(this.baseEndpoint + `/jobs/${this.jobName}/executions/${this.executionId}/logs/log-streams`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                if (response.data) {
                    this.logStreams = response.data.log_streams || [];
                }
                else {
                    this.logStreams = [];
                }

            }).catch((error) => {
                this.logStreams = [];
            })

        },
        getLogStreamByID(stream) {
            return axios.get(this.baseEndpoint + `/jobs/${this.jobName}/executions/${this.executionId}/logs?source=${stream.source}&log_stream_id=${stream.id}&type=${stream.type}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                if (response.data)
                    this.logStreamsData = response.data || [];
                else
                    this.logStreamsData = [];
            }).catch((error) => {
                this.logStreamsData = [];
            })

        },
        getExecutionLogs() {
            return axios.get(this.baseEndpoint + `/jobs/${this.jobName}/executions/${this.executionId}/logs`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).catch((error) => {
                this.logsError = true
                if (error.response.data.error)
                    //don't show pre-job errors
                    if (error.response.data.error.indexOf("the server rejected our request") != -1)
                        this.logs = "";
                    else
                        this.logs = error.response.data.error
                else
                    this.logs = error.message
            }).then((response) => {
                this.logsError = false;
                if (response && response.data) {
                    if (this.logs != response.data.Logs) {
                        this.logs = response.data.Logs

                        const regex = /Copied to S3: WrappedArray\((.*)\)/gi
                        const matches = [...this.logs.matchAll(regex)]
                        let locations = []
                        for (const match of matches) {
                            if (match[1]) {
                                let paths = match[1].split(',').map((path) => {
                                    return 's3://' + path;
                                })
                                locations = locations.concat(paths)
                            }
                        }
                        this.outputPaths = locations

                        //scroll to bottom of logs
                        setTimeout(() => {
                            var logsDiv = document.getElementById("logs");
                            if (logsDiv)
                                logsDiv.scrollTop = logsDiv.scrollHeight;
                        }, 0)
                    }
                    if (response.data.log_files) {
                        let logfiles = response.data.log_files
                        this.outputLogLink = logfiles["stdout.txt"].url;
                        this.outputLog = (logfiles["stdout.txt"].length / (1024 ** 2)).toFixed(2);
                        this.errorLogLink = logfiles["stderr.txt"].url;
                        this.errorLog = (logfiles["stderr.txt"].length / (1024 ** 2)).toFixed(2);
                    }
                } else {
                    this.logs = "No Log files";
                }

            }).finally(() => {
                this.loadMore = false;
            });
        },
        getExecutionConfig() {
            return axios.get(this.baseEndpoint + `/jobs/${this.jobName}/executions/${this.executionId}/configs`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then((response) => {
                if (response.data) {
                    let yamlContent = {};
                    const promotionExceutionFlag = this.$route.params.promotionId ? true : false;
                    if (response.data.system_files) {
                        for (const i of response.data.system_files) {
                            if (promotionExceutionFlag && i.relative_path == "vars.yaml") {
                                yamlContent[i.relative_path] = i.content
                            } else if (!promotionExceutionFlag) {
                                yamlContent[i.relative_path] = i.content
                            }

                        }
                    }
                    if (response.data.user_files) {
                        for (const i of response.data.user_files) {
                            if (promotionExceutionFlag && i.relative_path == "vars.yaml") {
                                yamlContent[i.relative_path] = i.content
                            } else if (!promotionExceutionFlag) {
                                yamlContent[i.relative_path] = i.content
                            }
                        }
                    }
                    if (Object.keys(yamlContent).length) {
                        this.tabItems = this.tabItems.map(function (el) {
                            if (el.slot == "config")
                                el.hide = false;

                            return el
                        });
                        this.activeTab = Object.keys(yamlContent)[0];
                        this.yamlContent = JSON.parse(JSON.stringify(yamlContent));

                    }

                }

            }).catch((err) => {
                console.log(err);

            });

        },
        timeRangeChange(timeRange) {
            this.timeRange = timeRange;
        },
        aggIntervalChange(interval) {
            this.aggInterval = interval;
        },
        getCountV2Metrics() {
            let countsPayload = {};

            if (this.timeRange) {
                countsPayload.aggregate = true;
                if (this.timeRange == "last_hour") {
                    const endTime = (this.status === 'running' ? dayjs.utc().format('YYYY-MM-DDTHH:mm:ss[Z]') : this.completedAt)
                    countsPayload.start_time = dayjs
                        .utc(endTime)
                        .startOf("hour")
                        .format("YYYY-MM-DD HH:mm:ss");
                    countsPayload.end_time = dayjs
                        .utc(endTime)
                        .add(1, "hour")
                        .startOf("hour")
                        .format("YYYY-MM-DD HH:mm:ss");

                } else if (this.timeRange == "custom_range") {
                    countsPayload.start_time = dayjs
                        .utc(this.customStartTime)
                        .format("YYYY-MM-DD HH:mm:ss");
                    countsPayload.end_time = dayjs
                        .utc(this.customEndTime)
                        .format("YYYY-MM-DD HH:mm:ss");
                    if (this.aggInterval)
                        countsPayload.time_interval = this.aggInterval;

                }


            }
            countsPayload.filter_dimensions = {
                "cid": this.$store.state.currentClient.cid,
                "sandbox_name": this.sandboxId,
                "job_name": this.jobName,
                "job_run_id": this.executionId
            },
                countsPayload.metrics = this.$store.state.amdp.countsMetricReqObj;


            return axios.post(`${this.$store.state.countsUrl}/v2/cids/${this.$store.state.currentClient.cid}/metrics/batch/query`, countsPayload, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                if (response.data)
                    this.countsV2Metrics = response.data.data || {};
                else
                    this.countsV2Metrics = {};
            }).catch((error) => {
                this.countsV2Metrics = {};
            }).finally(() => {
                this.metricLoading = false;
                if (!Object.keys(this.countsV2Metrics).length) {
                    this.convertMetricDataToUnits();
                }

            })
        },
        filterInputAndOutMetrics() {
            if (this.metrics) {
                this.filteredMetrics = this.metrics.filter((metric) => {
                    return (metric.type == "input" || metric.type == "output")
                });

                if (this.filteredMetrics.length == this.metrics.length)
                    this.showMetricsFilterBtn = false;
                else
                    this.showMetricsFilterBtn = true;
            }
        },
        convertMetricDataToUnits() {
            let self = this;
            this.metricLoading = false;
            this.metrics.map((metric) => {
                for (const key in metric) {
                    const filterMetricColumns = self.metricColumns.filter((column) => { return key == column.data })
                    if (!filterMetricColumns.length) {
                        let tempKey = key
                        if (key == "byte_count") {
                            self.metricColumns.push({
                                data: key,
                                title: tempKey.replace(/-/g, ''),
                                render: function (data, type, row) {
                                    if (!data) return "";
                                    if (!self.showDataInUnits) return data;
                                    return formatBytes(data)
                                },
                            })
                        } else if (key == "record_count") {
                            self.metricColumns.push({
                                data: key,
                                title: tempKey.replace(/-/g, ''),
                                render: function (data, type, row) {
                                    if (!data) return "";
                                    if (!self.showDataInUnits) return data;
                                    return Intl.NumberFormat("en", { notation: "compact", roundingMode: "floor", maximumFractionDigits: 2 }).format(data)
                                },
                            })
                        } else {
                            self.metricColumns.push({
                                data: key,
                                title: tempKey.replace(/-/g, '')
                            })

                        }

                    }
                }
            });

            this.filterInputAndOutMetrics();
        },

        showTab(tab) {
            this.activeTab = tab;
        },
        convertToTime(seconds) {
            return secondsToHourMinSec(seconds);
        },
        convertToRelativeTime(timestamp) {
            return relativeTime(Date.now(), timestamp);
        },
        async downloadFile(url, label) {
            this.isDownloading = true;
            await axios.get(url,
                {
                    responseType: 'blob'
                }).then(response => {

                    var blob = new Blob([response.data], { type: "text/plain" });
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = label;
                    a.click();
                });
            this.isDownloading = null
        },
        async saveJob() {
            this.loading = true;
            let toastoptions;
            try {
                let systemFileKeys = [];
                let userFileKeys = [];
                for (const i of Object.keys(this.yamlContent)) {
                    let isSystemFile = false;
                    if (this.job.system_files) {
                        for (const j of this.job.system_files) {
                            if (j.relative_path == i)
                                isSystemFile = true;
                        }
                    }
                    if (isSystemFile)
                        systemFileKeys.push(i)
                    else
                        userFileKeys.push(i)
                }

                this.jobObj.system_files = systemFileKeys.map((key) => {
                    return { content: (typeof this.yamlContent[key] === 'string') ? this.yamlContent[key] : JSON.stringify(this.yamlContent[key]), relative_path: key };
                });

                this.jobObj.user_files = userFileKeys.map((key) => {
                    return { content: (typeof this.yamlContent[key] === 'string') ? this.yamlContent[key] : JSON.stringify(this.yamlContent[key]), relative_path: key };
                });
                const response = await this.updateSandboxJob(this.jobObj);
                this.$emit('setSnapshot', this.jobObj);
                toastoptions = {
                    message: "Job updated successfully.",
                    type: "success"
                }
                this.$router.push({
                    name: "Sandbox Job Configuration",
                    params: {
                        sandboxId: `${this.$store.state.sandbox.currentSandbox}`,
                        jobId: `${this.jobObj.name}`
                    },
                });
            } catch (error) {
                if (error.response && error.response.data && error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;

                toastoptions = {
                    message: this.error || "Something went wrong when updating Job. Please contact support.",
                    type: "error"
                }
            } finally {
                this.loading = false;
                this.$store.dispatch('addToast', toastoptions);
            }
        },
        showRowDetail(index) {
            let streamIndex = this.tabItems.findIndex((tabs) => tabs.slot == "logStreams");

            this.currentLogStream = this.logStreams[index];
            this.currentLogStreamId = this.currentLogStream.id;
            if (this.tabItems[streamIndex + 1].slot == 'config') {
                this.tabItems.splice(4, 0, {
                    name: this.currentLogStreamId,
                    id: this.currentLogStreamId,
                    slot: this.currentLogStreamId

                });

            } else {
                this.tabItems.splice(4, 1, {
                    name: this.currentLogStreamId,
                    id: this.currentLogStreamId,
                    slot: this.currentLogStreamId

                });

            }
            this.$store.dispatch('setActiveTab', this.currentLogStreamId);
            this.getLogStreamByID(this.currentLogStream);
        },
        onDetailPageOpenClose(value) {
            this.showCloseBtn = false;
            this.detailPageOpen = value;
        },
        onDetailPageHover(value) {
            this.showCloseBtn = value;
        },
        openModal(data) {
            this.showModal({
                component: "ViewLog", data: {
                    sandboxName: this.$store.state.sandbox.currentSandbox, jobName: this.job.name, executionId: this.executionId,
                    screenlarge: true,
                    status: this.status.match(/\brunning\b/i) ? "running" : "",
                    streams: true,
                    streamData: data,
                    baseEndpoint: this.baseEndpoint
                }
            })
        },
        handleCustomRange({ startTime, endTime }) {
            this.customStartTime = startTime;
            this.customEndTime = endTime;
        },
        async onRefreshClick() {
            this.refreshBtnLoading = true
            await this.getExecutionStatus();
            this.checkAndGetCountsMetrics();
            this.getExecutionLogs();
            this.getExecutors();
            this.getLogStreams();
            this.getExecutionConfig();
            this.refreshBtnLoading = false
        },
        async onShowMoreClick() {
            this.count=1
            this.showMoreBtnLoading = true
            await this.handleJobChecking()
            this.showMoreBtnLoading = false
        }
    },

    async beforeMount() {
        let self = this;
        this.getExecutionConfig();
        await this.handleJobChecking();
        this.checkAndGetCountsMetrics();
        this.getExecutors();
        this.getLogStreams();
        this.enableRefreshBtn = true;
        this.enableShowMoreBtn = true;

        this.logStreamColumns = [{ data: "id" },
        { data: "logs" },
        { data: "source", title: "Source" },
        { data: "type", title: "Type" },
        { data: "timestamp", title: "Timestamp" },
        {
            data: "size_in_bytes", title: "Size", render: function (data, type, row) {
                if (!data) return "";
                if (!self.showLogDataInUnits) return data;
                return formatBytes(data)
            }
        }
        ]
    },

    beforeUnmount() {
        this.breakCheck = true;
        this.$emit('setSaveConfigBtn', false);
    },
    mounted() {
        this.$store.dispatch('setActiveTab', "Metrics")
        this.$emit('hideEdit', true);
        this.$emit('hideRunJob', true);
        this.$emit('hidePromoteBtn', true);
        this.$emit('setSaveConfigBtn', true);
    },
    emits: ['setRunClick', 'hideEdit', 'hideRunJob', 'showCancelJob', 'hidePromoteBtn', 'setSaveConfigBtn', 'getPromotionRunList']
};
</script>



<style scoped>
#logs {
    max-height: 60vh;
    border: 1px;
}

.no-border {
    box-shadow: none;
    border: none !important
}

.downloadLink {
    text-align: right;
}

.custom-refresh-btn {
    font-size: 15px;
    display: inline;
    /* Adjust text size */
}
.small-btn {
    font-size: 0.75rem; /* Reduce text size */
    line-height: 1; /* Make it compact */
    padding: 4px 6px; /* Very small padding */
}
</style>