<template>
  <div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
          <div><img src="../assets/logo2.png" style="width:250px" class="mx-auto d-block mb-3" alt="aqfer logo" /></div>
          <!-- Heading -->
          <!-- <h1 class="display-4 text-center mb-3">Sign in</h1> -->

          <!-- Subheading -->
          <p class="text-muted text-center">Client and Administration Portal</p>

          <!-- Submit -->
          <button id="login-btn" @click="loginClicked('cognito')" class="btn btn-lg w-100 btn-secondary mb-3"
            :disabled="isMailLoginClicked">
            <div v-if="isMailLoginClicked" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-else>
              <i class="fa fa-envelope me-3"></i>
              Sign in with Email
            </span>
          </button>

          <!-- Submit -->
          <button id="login-btn" @click="loginClicked('google')" class="btn btn-lg w-100 btn-primary mb-3"
            :disabled="isGoogleLoginClicked">
            <div v-if="isGoogleLoginClicked" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-else>
              <div class="gsi-material-icon-wrapper">
                <div class="gsi-material-button-icon">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
                    xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                    <path fill="#EA4335"
                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z">
                    </path>
                    <path fill="#4285F4"
                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z">
                    </path>
                    <path fill="#FBBC05"
                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z">
                    </path>
                    <path fill="#34A853"
                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                    </path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </svg>
                </div>
              </div>
              Sign in with Google
            </span>
          </button>

          <!-- Link -->
          <p class="text-center">
            <small class="text-muted text-center">
              Need Access? Contact us at <a href="mailto:support@aqfer.com">support@aqfer.com</a>.<br />
              Version {{ appVersion }}
            </small>
          </p>
        </div>
        <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <!-- Image -->
          <div class="bg-cover h-100 min-vh-100 mt-n1 me-n3" :style="{
            'background-image':
              'url(' + require('../assets/back2.jpg') + ')',
          }"></div>
        </div>
      </div>
      <!-- / .row -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      isMailLoginClicked: false,
      isGoogleLoginClicked: false,
      appVersion: process.env.VUE_APP_VERSION
    }
  },
  methods: {
    loginClicked(method) {
      if (method === 'cognito') {
        this.isMailLoginClicked = true;
      } else if (method === 'google') {
        this.isGoogleLoginClicked = true;
      }
      this.$store.dispatch('login', method);
    }
  }

};
</script>

<style scoped>
.gsi-material-icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background: white;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 8px;
}

.gsi-material-button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
}

/* Add this to properly align the icon with text */
button span {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Make sure the SVG is properly sized */
.gsi-material-button-icon svg {
  width: 18px;
  height: 18px;
}
</style>
